import React from 'react';
import ApiData from './ApiData';

const TopNews = () => {
  return (
    <>
    <ApiData title = "topstories" />
    </>
  )
}

export default TopNews;
