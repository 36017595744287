import React from 'react';
import ApiData from './ApiData';

const NewNews = () => {
  return (
    <>
    <ApiData title = "newstories" />
    </>
  )
}

export default NewNews;
