import React from 'react';
import ApiData from './ApiData';

const BestNews = () => {
  return (
    <>
    <ApiData title = "beststories" />
    </>
  )
}

export default BestNews;
